<template>
  <div class="home">
    <div class="cover-block">
      <div class="upper-text">
        <v-container fluid>
          <v-row justify="end">
            <v-col cols="auto" class="text-left text-md-left">
              <span class="red--text text-md-right">
                SCHEDULE A FREE INSPECTION <br />
                <b class="white--text">CALL US AT</b>
                <a
                  href="tel:310  822  1805"
                  class="white--text no-decoration hover-red"
                >
                  <span></span>(310) 822-1805<span></span
                ></a>
                <br class="d-block" />
                SAME DAY SERVICE AVAILABLE
              </span>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-img
        src="https://media.graphassets.com/lCBEoe4nQXuEg7vqf36c"
        height="100vh"
        class="d-none d-md-flex"
      >
        <div
          style="
            background-color: #080426;
            height: 100%;
            left: 0;
            mix-blend-mode: multiply;
            opacity: 0.65;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 0;
          "
        ></div>
        <v-container class="py-0" style="position: relative; z-index: 1">
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <v-row style="height: 100vh" no-gutters align="center">
                <v-col cols="12">
                  <v-img
                    class="cover-logo"
                    src="../assets/img/Logo-full.svg"
                    max-width="200"
                  ></v-img>
                </v-col>
                <v-col cols="12">
                  <h1 class="header-cover white--text" style="max-width: 600px">
                    DON'T LET TERMITES EAT AWAY YOUR HOME:<b class="red--text">
                      OUR EXPERTS CAN HELP!</b
                    >
                  </h1>
                </v-col>
                <v-col cols="12">
                  <div
                    class="d-inline-block bottom-text-in-cover"
                    style="max-width: 157px"
                  >
                    <p class="subtext white--text">
                      FAMILY OWNED <br class="d-md-none" />
                      AND OPERATED
                    </p>
                  </div>
                  <div
                    class="d-inline-block bottom-text-in-cover"
                    style="max-width: 157px"
                  >
                    <p class="subtext white--text">
                      OVER 50+ YEARS <br class="d-md-none" />
                      OF EXPERIENCE
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-img>
      <v-img
        src="https://media.graphassets.com/lCBEoe4nQXuEg7vqf36c"
        height="100vh"
        class="d-md-none"
        style="background-color: rebeccapurple"
      >
        <div
          style="
            background-color: #080426;
            height: 100%;
            left: 0;
            mix-blend-mode: multiply;
            opacity: 0.65;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 0;
          "
        ></div>
        <v-container class="py-0" style="position: relative">
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-row style="height: 100vh" no-gutters align="center">
                <v-col cols="12">
                  <v-img
                    class="cover-logo"
                    src="../assets/img/Logo-full.svg"
                    max-width="200"
                  ></v-img>
                </v-col>
                <v-col cols="12">
                  <h1 class="header-cover white--text">
                    DON'T LET TERMITES EAT AWAY YOUR HOME:<b class="red--text">
                      OUR EXPERTS CAN HELP!</b
                    >
                  </h1>
                </v-col>
                <v-col cols="12">
                  <div
                    class="d-inline-block bottom-text-in-cover"
                    style="max-width: 157px"
                  >
                    <p class="subtext white--text">
                      FAMILY OWNED <br class="d-md-none" />
                      AND OPERATED
                    </p>
                  </div>
                  <div
                    class="d-inline-block bottom-text-in-cover"
                    style="max-width: 157px"
                  >
                    <p class="subtext white--text">
                      OVER 50+ YEARS <br class="d-md-none" />
                      OF EXPERIENCE
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-img>
    </div>
    <v-container
      fluid
      class="second-block"
      v-if="$vuetify.breakpoint.mdAndUp || showMobile"
    >
      <v-row style="height: 100%">
        <v-col cols="12" md="6" class="pa-0">
          <v-carousel
            :height="carouselHeight"
            hide-delimiters
            :show-arrows="false"
            v-model="carouselIndex"
          >
            <v-carousel-item :eager="$vuetify.breakpoint.mdAndUp">
              <v-img
                src="../assets/img/Termites-Sec-2-1.jpg"
                :eager="$vuetify.breakpoint.mdAndUp"
              ></v-img>
            </v-carousel-item>
            <v-carousel-item :eager="$vuetify.breakpoint.mdAndUp">
              <v-img
                src="../assets/img/Termites-Sec-2-2.jpg"
                :eager="$vuetify.breakpoint.mdAndUp"
              ></v-img>
            </v-carousel-item>
            <!-- <v-carousel-item :eager="$vuetify.breakpoint.mdAndUp">
              <v-img
                src="../assets/img/Termites-Sec-2-3.jpg"
                :eager="$vuetify.breakpoint.mdAndUp"
              ></v-img>
            </v-carousel-item> -->
          </v-carousel>
        </v-col>
        <v-col cols="12" md="6" class="py-0">
          <v-row justify="center" no-gutters style="height: 100%">
            <v-col cols="11" class="pb-12 pb-md-3">
              <v-row style="height: 100%" class="py-12">
                <v-col cols="12">
                  <v-row>
                    <!-- <v-col cols="6" md="auto">
                        <div class="d-inline-block container-subtext">
                          <p class="subtext">
                            STEP {{ carouselAux + 1 }}
                          </p>
                        </div>
                      </v-col> -->
                    <!-- <v-col cols="6" md="auto">
                        <div class="d-inline-block container-subtext">
                          <p class="subtext">
                            CORRECTIVE <br> OR PREVENTIVE
                          </p>
                        </div>
                      </v-col> -->
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <div
                    v-bind:class="{
                      dissolve:
                        carouselDissolve != 0 &&
                        carouselAux != carouselDissolve,
                      visible: carouselShow == 0,
                    }"
                    class="title-products"
                    v-show="carouselAux == 0"
                  >
                    <h1 class="red--text uppercase">ABOUT TERMITES</h1>
                  </div>

                  <div
                    v-bind:class="{
                      dissolve:
                        carouselDissolve != 1 &&
                        carouselAux != carouselDissolve,
                      visible: carouselShow == 1,
                    }"
                    class="title-products"
                    v-show="carouselAux == 1"
                  >
                    <h1 class="red--text uppercase">
                      HOW TO IDENTIFY TERMITES?
                    </h1>
                  </div>

                  <!-- <div
                    v-bind:class="{
                      dissolve:
                        carouselDissolve != 2 &&
                        carouselAux != carouselDissolve,
                      visible: carouselShow == 2,
                    }"
                    class="title-products"
                    v-show="carouselAux == 2"
                  >
                    <h1 class="red--text uppercase">
                      HEALTH <br />
                      RISKS
                    </h1>
                  </div> -->
                </v-col>
                <v-col cols="12">
                  <div
                    class="description-products"
                    v-bind:class="{
                      dissolve:
                        carouselDissolve != 0 &&
                        carouselAux != carouselDissolve,
                      visible: carouselShow == 0,
                    }"
                    v-show="carouselAux == 0"
                  >
                    <p style="max-width: 450px">
                      Termites are small insects that feed on wood and other
                      cellulose-based materials. These pests can cause
                      significant damage to homes and buildings, and if left
                      untreated, can even compromise the structural integrity of
                      a building. Termites are commonly found in warm, humid
                      climates, such as Los Angeles.
                    </p>
                  </div>

                  <div
                    class="description-products"
                    v-bind:class="{
                      dissolve:
                        carouselDissolve != 1 &&
                        carouselAux != carouselDissolve,
                      visible: carouselShow == 1,
                    }"
                    v-show="carouselAux == 1"
                  >
                    <p style="max-width: 450px">
                      It can be difficult to identify a termite infestation, but
                      some common signs include the presence of mud tubes (small
                      tunnels made of mud that termites use to travel from their
                      nests to their food sources), discarded termite wings,
                      droppings, and hollow-damaged wood. If you suspect that
                      you have a termite infestation, it's important to contact
                      a professional exterminator as soon as possible.
                    </p>
                  </div>

                  <div
                    class="description-products"
                    v-bind:class="{
                      dissolve:
                        carouselDissolve != 2 &&
                        carouselAux != carouselDissolve,
                      visible: carouselShow == 2,
                    }"
                    v-show="carouselAux == 2"
                  >
                    <p style="max-width: 450px">
                      Termites themselves are not harmful to humans, but their
                      presence can pose a health risk in other ways. For
                      example, termites can attract other pests like ants and
                      cockroaches, which can carry diseases and contaminate
                      food. Additionally, if termites are eating away at the
                      structure of a building, it can become unstable and
                      potentially dangerous.
                    </p>
                  </div>

                  <div
                    class="description-products"
                    v-bind:class="{
                      dissolve:
                        carouselDissolve != 3 &&
                        carouselAux != carouselDissolve,
                      visible: carouselShow == 3,
                    }"
                    v-show="carouselAux == 3"
                  >
                    <p style="max-width: 450">
                      Bed Bugs may enter your home undetected through luggage,
                      clothing, used beds and couches, and other items. They can
                      move quickly over floors, walls, and ceilings.
                    </p>
                  </div>
                  <div
                    class="description-products"
                    v-bind:class="{
                      dissolve:
                        carouselDissolve != 4 &&
                        carouselAux != carouselDissolve,
                      visible: carouselShow == 4,
                    }"
                    v-show="carouselAux == 4"
                  >
                    <p style="max-width: 450px">
                      These insects usually are brought into your home in
                      packages that are already infested. You can notice the
                      presence of small beetles or moths shortly after storing
                      away foods.
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="11"
              align-self="end"
              class="py-12 mt-12 mt-md-0 py-md-12"
            >
              <v-btn
                icon
                height="12"
                width="12"
                class="mr-3"
                @click="carouselIndex = 0"
                aria-label="Show first slide"
              >
                <v-icon :color="slide1" size="12"
                  >mdi-checkbox-blank-circle</v-icon
                >
              </v-btn>
              <v-btn
                icon
                height="12"
                width="12"
                class="mr-3"
                @click="carouselIndex = 1"
                aria-label="Show second slide"
              >
                <v-icon :color="slide2" size="12"
                  >mdi-checkbox-blank-circle</v-icon
                >
              </v-btn>
              <!-- <v-btn
                icon
                height="12"
                width="12"
                class="mr-3"
                @click="carouselIndex = 2"
                aria-label="Show third slide"
              >
                <v-icon :color="slide3" size="12"
                  >mdi-checkbox-blank-circle</v-icon
                >
              </v-btn> -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-img
      :src="thirdBlockImage"
      v-if="$vuetify.breakpoint.mdAndUp || showMobile"
      style="position: relative"
    >
      <div
        style="
          background-color: #080426;
          height: 100%;
          left: 0;
          mix-blend-mode: multiply;
          opacity: 0.65;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 0;
        "
        class="d-md-none"
      ></div>
      <v-container style="position: relative">
        <v-row style="height: 100vh" align="center">
          <v-col cols="12" md="6">
            <v-row>
              <v-col>
                <h1 class="white--text">
                  <span class="red--text">TERMITES?</span>
                  <br />
                  WE TAKE NO PRISONERS
                </h1>
                <ul class="white--text text-list mt-12">
                  <li>Residential</li>
                  <li>Commercial</li>
                  <li>Food Service areas</li>
                  <li>Medical Centers</li>
                  <li>Housing, Students dorms</li>
                  <li>And more...</li>
                </ul>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6"> </v-col>
        </v-row>
      </v-container>
    </v-img>
    <v-container fluid>
      <v-row>
        <v-col class="pa-0">
          <v-carousel
            :show-arrows="false"
            :hide-delimiter-background="true"
            :height="promoHeight"
            :hide-delimiters="true"
          >
            <!-- <v-carousel-item>
                <video src="https://media.graphassets.com/pASPavRiTUmV7jJ7u1y7"
                  style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover;" autoplay muted
                  playsinline loop></video>
                <div class="hero-carousel-item" style="position: relative; height: 100%;">
                  <v-row style="height:100%" align="center">
                    <v-col cols="12" class="mt-12 mt-md-0">
                      <div class="title-container text-center"><span class="title">Specials</span></div>
                    </v-col>
                    <v-col cols="12">
                      <div class="description-container"><a href="/contact" class="" style="text-decoration: none;">
                          <div class="description mx-auto text-center">
                            <p>10% off your next pest control service using code NSE20</p>
                          </div>
                        </a></div>
                    </v-col>
                    <v-col cols="12" align-self="end" class="pb-0">
                      <div class="subtitle-container">
                        <div class="subtitle text-center text-md-right ml-auto mr-auto ml-md-auto mr-md-3 mb-12 mb-md-0">
                          <p>Mention THE Discount Code WHEN Scheduling YOUR FREE INSPECTION</p>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-carousel-item> -->
            <v-carousel-item>
              <video
                src="https://media.graphassets.com/GOP6UBAYTeKL8srC33tA"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                "
                autoplay
                muted
                playsinline
                loop
              ></video>
              <div
                class="hero-carousel-item"
                style="position: relative; height: 100%"
              >
                <v-row style="height: 100%" align="center">
                  <v-col cols="12">
                    <div class="title-container text-center">
                      <span class="title">Specials</span>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div class="description-container">
                      <span class="" style="text-decoration: none">
                        <div class="description mx-auto text-center">
                          <p>
                            5% OFF ANY TERMITE CONTROL SERVICE USING CODE TCS24. VALID UNTIL SEPTEMBER 31ST.
                          </p>
                        </div>
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="12" align-self="end" class="pb-0">
                    <div class="subtitle-container">
                      <div
                        class="subtitle text-center text-md-right ml-auto mr-auto ml-md-auto mr-md-3 mb-12 mb-md-0"
                      >
                        <p>
                          Mention THE Discount Code WHEN Scheduling YOUR FREE
                          INSPECTION
                        </p>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="$vuetify.breakpoint.mdAndUp || showMobile">
      <v-row class="my-12">
        <v-col class="text-center">
          <h2 class="mx-auto" style="max-width: 694px">
            Our <span class="red--text"> termite elimination techniques </span>
            MEET the highest standards of satisfaction and safety available
          </h2>
        </v-col>
      </v-row>
      <v-row class="my-12" justify="center">
        <v-col class="d-none d-md-block" md="1"> </v-col>
        <v-col cols="10" md="4" class="text-center">
          <v-img
            src="../assets/img/Termites-Sec-5-1.jpg"
            :eager="$vuetify.breakpoint.mdAndUp"
          >
          </v-img>
          <h3 class="my-3">TENT FUMIGATION</h3>
          <p>
            During the tenting process, a tent is placed over the entire
            structure and Viking gas is released in to the structure. This gas
            penetrates all areas of the building, including hard-to-reach spaces
            where termites may be hiding, and effectively kills all termites
            within.
          </p>
        </v-col>
        <v-col class="d-none d-md-block" md="1"> </v-col>
        <v-col cols="10" md="4" class="text-center">
          <v-img
            src="../assets/img/termite-control-Sec-5-2.jpg"
            :eager="$vuetify.breakpoint.mdAndUp"
          ></v-img>
          <h3 class="my-3">PREVENTATIVE TREATMENT</h3>
          <p>
            This treatment involves the use of Bora-Care which is a wood
            preservative. The material is applied to all visible, accessible,
            treatable lumber in a structure. Bora-Care penetrates into the
            lumber and kills existing termite infestations and will protect the
            lumber against future infestations due to its long lasting residual
            effect. Bora-Care is also very effective in stoping and preventing
            fungus/dryrot.
          </p>
        </v-col>
        <v-col class="d-none d-md-block" md="1"> </v-col>
        <v-col cols="10" md="4" class="text-center">
          <v-img
            src="../assets/img/Termites-Sec-5-3.jpg"
            :eager="$vuetify.breakpoint.mdAndUp"
          ></v-img>
          <h3 class="my-3">LOCALIZED TREATMENT</h3>
          <p>
            Our localized treatments involves treating termite infested wood
            with a termiticede. The Termiticide is a slow-acting, this takes
            advantage of termites’ social nature to wipe out the entire colony.
            These treatments are usually performed in one day and there is no
            need to vacate the property.
          </p>
        </v-col>
        <v-col class="d-none d-md-block" md="1"> </v-col>
        <v-col cols="10" md="4" class="text-center">
          <v-img
            src="../assets/img/Termites-Sec-5-4.jpg"
            :eager="$vuetify.breakpoint.mdAndUp"
          ></v-img>
          <h3 class="my-3">HEAT TREATMENT</h3>
          <p>
            A heat Treatment is a non chemical approach for the control of
            termites and other pests. It involves the use of heat to get the
            core of the lumber in a structure to 130 degrees. This kill the
            termites in the area being treated. A heat treatment is usually
            performed in one day.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <div
      style="background: #03002d"
      class="py-12"
      v-if="$vuetify.breakpoint.mdAndUp || showMobile"
    >
      <v-container>
        <v-row class="blue-block" align="center">
          <v-col cols="12" md="5" order="2" order-md="1">
            <v-img
              aspect-ratio="1"
              src="../assets/img/Section-5.jpg"
              max-width="387"
              class="mx-auto"
              contain
            ></v-img>
          </v-col>
          <v-col cols="12" md="7" class="text-center" order="1" order-md="2">
            <h1 class="red--text cta">PEST PROBLEM?</h1>
            <h1 class="red--text cta">CALL US</h1>
            <a
              href="tel:3108221805"
              class="white--text cta no-decoration hover-red"
              ><span>(</span>310<span>)</span> 822-1805</a
            >
            <div class="mt-6">
              <h3 class="d-md-inline-block white--text mx-6 uppercase">
                LOS ANGELES COUNTY
              </h3>
              <h3 class="d-md-inline-block white--text">ORANGE COUNTY</h3>
              <h3 class="d-md-inline-block white--text mx-6 uppercase">
                riverside COUNTY
              </h3>
              <h3 class="d-md-inline-block white--text">
                SAN BERNARDINO COUNTY
              </h3>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container v-if="$vuetify.breakpoint.mdAndUp || showMobile">
      <v-row class="my-12">
        <v-col class="text-center">
          <h2 class="mx-auto">What our customers say</h2>
          <span class="raiting-text my-6 d-block">
            <b>4.5 raiting</b> of 93 reviews
          </span>
          <v-btn
            href="https://www.yelp.com/"
            target="_blank"
            color="#03002d"
            dark
            tile
            width="273"
            height="60"
            aria-label="Leave a review"
            ><span class="send-text">Leave a review</span></v-btn
          >
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="auto" sm="4" md="4" lg="auto">
          <v-card
            max-width="302"
            min-height="320"
            color="#FAFAFA"
            class="pa-6"
            elevation="0"
          >
            <v-avatar size="64">
              <v-img
                src="https://s3-media0.fl.yelpcdn.com/photo/5BOhlz5i63Rov8dUOQAhHQ/348s.jpg"
              ></v-img>
            </v-avatar>
            <span class="review-name d-block mt-2 mb-3">Judith H.</span>
            <v-icon color="#F49E39" size="26">mdi-star</v-icon>
            <v-icon color="#F49E39" size="26">mdi-star</v-icon>
            <v-icon color="#F49E39" size="26">mdi-star</v-icon>
            <v-icon color="#F49E39" size="26">mdi-star</v-icon>
            <v-icon color="#F49E39" size="26">mdi-star</v-icon>
            <p class="mt-3 review-text">
              They treated my house for termites. They were very prompt and
              professional. I highly recommend New Systems, they are affordable
              and great customer service.
            </p>

            <v-row align="center">
              <v-col cols="auto">
                <a
                  href="https://www.yelp.com/biz/new-systems-exterminating-culver-city-5?hrid=ZRKguQ3t3Wh_tnwzHGqpjA&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)"
                  class="no-decoration"
                  target="_blank"
                  aria-label="Read the full review"
                >
                  <v-img
                    src="../assets/img/yelp-logo.png"
                    width="33"
                    class="d-inline-block"
                  ></v-img>
                </a>
              </v-col>
              <v-col cols="auto">
                <span class="d-inline-block">7/7/2022</span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col
          cols="auto"
          sm="4"
          md="4"
          lg="auto"
          class="px-12 px-sm-3 px-md-3 px-lg-12"
        >
          <v-card
            max-width="302"
            min-height="320"
            color="#FAFAFA"
            class="pa-6"
            elevation="0"
          >
            <v-avatar size="64">
              <v-img src="../assets/img/user.png"></v-img>
            </v-avatar>
            <span class="review-name d-block mt-2 mb-3">Gerylinn B.</span>
            <v-icon color="#F49E39" size="26">mdi-star</v-icon>
            <v-icon color="#F49E39" size="26">mdi-star</v-icon>
            <v-icon color="#F49E39" size="26">mdi-star</v-icon>
            <v-icon color="#F49E39" size="26">mdi-star</v-icon>
            <v-icon color="#F49E39" size="26">mdi-star</v-icon>
            <p class="mt-3 review-text">
              Jesus and Alonso were absolutely amazing!!! These guys are highly
              professional, know their stuff, are honest, helpful and reasonable
              prices!!
            </p>
            <v-row align="center">
              <v-col cols="auto">
                <a
                  href="https://www.yelp.com/biz/new-systems-exterminating-culver-city-5?hrid=oEBnOBCQA6P6v3HuwQAIVQ&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)"
                  class="no-decoration"
                  target="_blank"
                  aria-label="Read the full review"
                >
                  <v-img
                    src="../assets/img/yelp-logo.png"
                    width="33"
                    class="d-inline-block"
                  ></v-img>
                </a>
              </v-col>
              <v-col cols="auto">
                <span class="d-inline-block">6/23/2022</span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="auto" sm="4" md="4" lg="auto">
          <v-card
            max-width="302"
            min-height="320"
            color="#FAFAFA"
            class="pa-6"
            elevation="0"
          >
            <v-avatar size="64">
              <v-img src="../assets/img/user.png"></v-img>
            </v-avatar>
            <span class="review-name d-block mt-2 mb-3">Rudolf Z.</span>
            <v-icon color="#F49E39" size="26">mdi-star</v-icon>
            <v-icon color="#F49E39" size="26">mdi-star</v-icon>
            <v-icon color="#F49E39" size="26">mdi-star</v-icon>
            <v-icon color="#F49E39" size="26">mdi-star</v-icon>
            <v-icon color="#F49E39" size="26">mdi-star</v-icon>
            <p class="mt-3 review-text">
              The Service visit was on time and Daniel made a through inspection
              of our house inside and outside. We had a problem with wasps
              inside the house. Daniel made recommendations to so...Read more
            </p>
            <v-row align="center">
              <v-col cols="auto">
                <a
                  href="https://www.yelp.com/biz/new-systems-exterminating-culver-city-5?hrid=AIbRneFPtV3yGCmeQySUtQ&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)"
                  class="no-decoration"
                  target="_blank"
                  aria-label="Read the full review"
                >
                  <v-img
                    src="../assets/img/yelp-logo.png"
                    width="33"
                    class="d-inline-block"
                  ></v-img>
                </a>
              </v-col>
              <v-col cols="auto">
                <span class="d-inline-block">5/28/2022</span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div
      class="py-12 my-md-12"
      v-if="$vuetify.breakpoint.mdAndUp || showMobile"
    >
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-img
              src="../assets/img/Section-7.jpg"
              width="100%"
              height="100%"
            ></v-img>
          </v-col>
          <v-col cols="12" md="6" class="text-center">
            <v-row justify="center">
              <v-col cols="11" md="9">
                <h2 class="red--text uppercase">got termits?</h2>
                <h2>WE CAN HANDLE IT</h2>

                <span class="d-block mt-5 mb-1"
                  >Get in touch, we will reply as soon as possible</span
                >

                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-container>
                    <v-row class="my-0">
                      <v-col class="py-2">
                        <v-text-field
                          outlined
                          label="Name"
                          hide-details
                          :rules="requiredRules"
                          v-model="form.name"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="my-0">
                      <v-col cols="6" class="py-2 pr-2">
                        <v-text-field
                          outlined
                          label="Phone"
                          hide-details
                          :rules="requiredRules"
                          v-model="form.phone"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="py-2 pl-2">
                        <v-text-field
                          outlined
                          label="Email"
                          hide-details
                          :rules="emailRules"
                          v-model="form.email"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="my-0">
                      <v-col class="py-2">
                        <v-text-field
                          outlined
                          label="Subject"
                          hide-details
                          :rules="requiredRules"
                          v-model="form.subject"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="my-0">
                      <v-col class="py-0 pt-2">
                        <v-textarea
                          label="Message"
                          auto-grow
                          outlined
                          rows="4"
                          row-height="25"
                          hide-details
                          :rules="requiredRules"
                          v-model="form.message"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row class="my-1">
                      <v-col>
                        <v-btn
                          color="#03002d"
                          dark
                          tile
                          width="273"
                          height="60"
                          @click="validate"
                          aria-label="Submit the form"
                        >
                          <span class="send-text">SEND</span>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-snackbar v-model="snackbar">
                      Thank you!

                      <template v-slot:action="{ attrs }">
                        <v-btn
                          color="white"
                          text
                          v-bind="attrs"
                          @click="snackbar = false"
                        >
                          Close
                        </v-btn>
                      </template>
                    </v-snackbar>
                  </v-container>
                </v-form>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-footer
      color="#03002d"
      class="py-8"
      v-if="$vuetify.breakpoint.mdAndUp || showMobile"
    >
      <v-container class="py-0" fluid>
        <v-row>
          <v-col cols="12" md="3">
            <v-img
              src="../assets/img/logo-horizontal.svg"
              max-width="350"
              class="logo-footer"
              contain
            ></v-img>
          </v-col>
          <v-col cols="12" md="2" offset-md="1">
            <v-row>
              <v-col cols="12">
                <span class="red--text--footer">
                  OVER 50+ <br />
                  YEARS OF <br />
                  EXPERIENCE
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="3">
            <v-row justify="center" class="mt-2 mt-md-0">
              <v-col cols="12" md="8">
                <v-row>
                  <v-col cols="6" md="12" class="py-0">
                    <span class="d-block texts-small white--text"
                      >Monday to Saturday</span
                    >
                    <span class="d-block texts-small white--text"
                      >7:00 AM - 5:00 PM</span
                    >
                  </v-col>
                  <v-col cols="6" md="12" class="py-0">
                    <p class="texts-small white--text mt-md-4">
                      11259 Regentview Blvd. <br />
                      Unit A, Downey CA 90241
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="3">
            <span
              class="uppercase red--text--footer footer-subtitle d-block mb-md-4"
              >Need pest control services?</span
            >
            <!-- <span class="call-us-a white--text d-block">CALL US AT</span> -->
            <a
              href="tel:3108221805"
              class="call-us-a white--text no-decoration hover-red"
            >
              <span class="d-block white--text mt-md-3"
                ><b>(310) 822-1805</b></span
              >
            </a>
          </v-col>
        </v-row>
        <v-row class="py-0" align="center">
          <v-col cols="12" md="4" class="pb-0" order="3" order-md="1">
            <v-row align="center">
              <v-col cols="auto">
                <a
                  href="https://www.facebook.com/newsystemsexterminating"
                  class="no-decoration d-inline-block mr-2"
                  target="_blank"
                  aria-label="View the Facebook Profile"
                >
                  <v-img src="/facebook.svg"></v-img>
                </a>
                <a
                  href="https://www.instagram.com/newsystemsexterminating/"
                  class="no-decoration d-inline-block mr-2"
                  target="_blank"
                  aria-label="View the Instagram Profile"
                >
                  <v-img src="/Instagram.svg"></v-img>
                </a>
                <a
                  href="https://www.yelp.com/biz/new-systems-exterminating-culver-city-5"
                  class="no-decoration d-inline-block"
                  target="_blank"
                  aria-label="Read the full review"
                >
                  <v-img src="/Yelp.svg"></v-img>
                </a>
              </v-col>
              <v-col>
                <div class="white--text d-inline-block pl-12 texts-small">
                  All rights reserved {{ year }}
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="4" class="pb-0" order="1" order-md="2">
            <router-link
              to="/"
              class="texts-small d-inline-block not-underlined white--text"
              >Privacy Policy</router-link
            >
            <router-link
              to="/"
              class="texts-small d-inline-block ml-6 not-underlined white--text"
              >Terms of use</router-link
            >
          </v-col>

          <v-col
            cols="12"
            md="3"
            offset-md="1"
            class="pb-0"
            order="0"
            order-md="3"
          >
            <v-icon class="mr-2" color="white">mdi-email</v-icon>
            <a
              href="mailto:info@newsystemsexterminating.com"
              class="texts-small d-inline-block white--text not-underlined mb-4 mb-md-0"
              >Send us an email</a
            >
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>
  
<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},

  data() {
    return {
      carouselIndex: 0,
      carouselAux: 0,
      carouselDissolve: 0,
      carouselShow: 0,
      valid: true,
      requiredRules: [(v) => !!v || "* Required"],
      emailRules: [
        (v) => !!v || "* Required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      snackbar: false,
      showMobile: false,
      form: {
        name: "",
        phone: "",
        email: "",
        subject: "",
        message: "",
      },
    };
  },
  mounted: function () {
    setTimeout(() => {
      this.showMobile = true;
    }, 1500);
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        fetch(
          "https://cms.newsystemsexterminating.com/api/forms/submit/ContactForm?token=dca8645aab2ace9f08a5c5260dfce4",
          {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              form: this.form,
            }),
          }
        )
          .then((entry) => entry.json())
          .then(() => (this.snackbar = true))
          .then(() => this.$refs.form.reset());
      }
    },
  },
  watch: {
    carouselIndex: function () {
      if (this.$vuetify.breakpoint.width >= 960) {
        this.$vuetify.goTo(this.$vuetify.breakpoint.height);
      }
      this.carouselDissolve = this.carouselIndex;
      setTimeout(() => {
        this.carouselAux = this.carouselIndex;
      }, 600);
      setTimeout(() => {
        this.carouselShow = this.carouselIndex;
      }, 650);
    },
  },
  computed: {
    thirdBlockImage: function () {
      if (this.$vuetify.breakpoint.width >= 960) {
        return "https://media.graphassets.com/498JQV1wQLGXwilWWFRp";
      } else {
        return "https://media.graphassets.com/tcbpWzaoSzWFSMwJ1M7I";
      }
    },
    year: function () {
      return new Date().getFullYear();
    },
    promoHeight: function () {
      if (this.$vuetify.breakpoint.width >= 960) {
        return "500";
      } else {
        return "360";
      }
    },
    carouselHeight: function () {
      if (this.$vuetify.breakpoint.width >= 960) {
        return "100vh";
      } else {
        return "auto";
      }
    },
    slide1: function () {
      if (this.carouselIndex == 0) {
        return "red";
      }

      return "black";
    },
    slide2: function () {
      if (this.carouselIndex == 1) {
        return "red";
      }

      return "black";
    },
    slide3: function () {
      if (this.carouselIndex == 2) {
        return "red";
      }

      return "black";
    },
    slide4: function () {
      if (this.carouselIndex == 3) {
        return "red";
      }

      return "black";
    },
    slide5: function () {
      if (this.carouselIndex == 4) {
        return "red";
      }

      return "black";
    },
  },
};
</script>
  