import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import PestControl from '../views/PestControl.vue'
import RodentControl from '../views/RodentControl.vue'
import TermiteControl from '../views/TermiteControl.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/pest-control',
    name: 'Pest Control',
    component: PestControl
  },
  {
    path: '/rodent-control',
    name: 'Rodent Control',
    component: RodentControl
  },
  {
    path: '/termite-control',
    name: 'Termite Control',
    component: TermiteControl
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
