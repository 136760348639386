<template>
  <v-app>

    <v-app-bar
      fixed
      v-model="showMenuBar"
      flat
      color="#03002d"
      height="80"
      
      v-if="$vuetify.breakpoint.smAndUp"
    >
      <v-container fluid>
        <v-row align="center">
          <v-col>
            <v-img src="@/assets/img/logo-horizontal.svg" height="58" contain position="left center" @click="$vuetify.goTo(0)" style="cursor:pointer"></v-img>
          </v-col>
          <v-col class="text-right">
            <div class="header-subtitle white--text">
              <p class=" my-0">
                <strong>SCHEDULE a free INSPECTION</strong> <br> call us at (310) 822-1805 <strong class="d-block">SAME DAY SERVICE AVAILABLE</strong>
              </p>
            </div>
            <!-- <span class="menu-text white--text">
              CALL NOW TO SCHEDULE <br>
              YOUR FREE INSPECTION <a href="tel:310  822  1805" class="menu-text white--text no-decoration hover-red"> <span>(</span>310<span>)</span> 8221805</a>
            </span> -->
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-app-bar
      fixed
      v-model="showMenuBar"
      flat
      color="#03002d"
      height="80"
      class="px-0"
      v-else
    >
      <v-container class="px-0" fluid style="height: 100%;">
        <v-row align="center" style="height: 100%;">
          <v-col cols="6">
            <v-img src="@/assets/img/logo-horizontal.svg" max-width="180"></v-img>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn 
              rounded
              small
            >
              Call us</v-btn>
              <br>
              <span class="same-day-mobile-app-bar">SAME DAY SERVICE AVAILABLE </span>
          </v-col>
          <v-col class="text-right">
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>


    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    showMenuBar: false
  }),
  created() {
    window.addEventListener("scroll", this.showInScroll, {passive:true});
  },
  methods: {
    showInScroll: function () {
      var animate = document.querySelectorAll(".second-block");
      let scrollTop = document.documentElement.scrollTop;

      //if(scrollTop >= this.$vuetify.breakpoint.height){
      if(scrollTop >= 100){
        this.showMenuBar = true
      }else{
        this.showMenuBar = false;
      }

      console.log(scrollTop);
    }
  },  
};
</script>
