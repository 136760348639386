<template>
    <div class="cover-block">
        <div class="upper-text">
            <v-container fluid>
                <v-row justify="end">
                    <v-col cols="auto" class="text-left text-md-left">
                        <span class="red--text text-md-right">
                            SCHEDULE A FREE INSPECTION <br>
                            <b class="white--text">CALL US AT</b> <a href="tel:310  822  1805"
                                class="white--text no-decoration hover-red"> <span></span>(310) 822-1805<span></span></a> <br
                                class="d-block">
                            SAME DAY SERVICE AVAILABLE
                        </span>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-img :src="source" height="100vh" class="d-none d-md-flex">
            <div style="    background-color: #080426;
          height: 100%;
          left: 0;
          mix-blend-mode: multiply;
          opacity: .65;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 0;">

            </div>
            <v-container class="py-0" style="position: relative; z-index: 1;">
                <v-row no-gutters>
                    <v-col cols="12" md="12">
                        <v-row style="height: 100vh;" no-gutters align="center">
                            <v-col cols="12">
                                <v-img class="cover-logo" src="../assets/img/Logo-full.svg" max-width="200"></v-img>
                            </v-col>
                            <v-col cols="12">
                                <h1 class="header-cover white--text" v-html="header"></h1>
                            </v-col>
                            <v-col cols="12">
                                <div class="d-inline-block bottom-text-in-cover" style="max-width: 157px">
                                    <p class="subtext white--text" v-html="subtitle">
                                        
                                    </p>
                                </div>
                                <div class="d-inline-block bottom-text-in-cover" style="max-width: 157px">
                                    <p class="subtext white--text" v-html="secondSubtitle">
                                        
                                    </p>
                                </div>
                            </v-col>

                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-img>
        <v-img :src="mobileSource" height="100vh" class="d-md-none"
            style="background-color:rebeccapurple;">
            <div style="    background-color: #080426;
          height: 100%;
          left: 0;
          mix-blend-mode: multiply;
          opacity: .65;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 0;">

            </div>
            <v-container class="py-0" style="position: relative;">
                <v-row no-gutters>
                    <v-col cols="12" md="6">
                        <v-row style="height: 100vh;" no-gutters align="center">
                            <v-col cols="12">
                                <v-img class="cover-logo" src="../assets/img/Logo-full.svg" max-width="200"></v-img>
                            </v-col>
                            <v-col cols="12">
                                <h1 class="header-cover white--text">THE <b class="red--text">BEST <br> PEST & TERMITE
                                        CONTROL</b> IN
                                    THE <br> LOS ANGELES AREA</h1>
                            </v-col>
                            <v-col cols="12">
                                <div class="d-inline-block bottom-text-in-cover" style="max-width: 157px">
                                    <p class="subtext white--text" v-html="subtitle">
                                        
                                    </p>
                                </div>
                                <div class="d-inline-block bottom-text-in-cover" style="max-width: 157px">
                                    <p class="subtext white--text" v-html="secondSubtitle">
                                        
                                    </p>
                                </div>
                            </v-col>

                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-img>
    </div>
</template>

<script>
export default {
    props: {
        source: String,
        mobileSource: String,
        header: String,
        subtitle: String,
        secondSubtitle: String
    },
}
</script>